#loadScreen {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  background: rgba(53, 53, 53, 0.9);
  filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loadRing,
span {
  box-sizing: border-box;
}

.loadRing {
  position: relative;
  width: 200px;
  height: 200px;
}

.loadRing span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(calc(18deg * var(--i)));
}

.loadRing span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: #e7f1f6;
  border-radius: 50%;
  box-shadow: 0 0 10px #e7f1f6;
  animation: animate 1s linear infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes animate {
  0% {
    transform: scale(1.5);
  }
  80%,
  100% {
    transform: scale(0);
  }
}
