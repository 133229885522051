nav {
  position: relative;
  top: 0;
  width: 100%;
  background: #8c8c8c70;
  color: #424242;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 99;
  backdrop-filter: blur(10px);
  img {
    width: 40px;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid #999;
  }
  .link__container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    a,
    a:visited {
      position: relative;
      display: grid;
      grid-template-columns: 60px 20px;
      align-items: center;
      gap: 6px;
      padding: 4px;
      justify-content: center;
      color: #ffffff;
      text-decoration: none;
      transition: background-color 0.3s ease-in-out;
      &:hover {
        background: #4e4e4e55;
      }
    }
  }
}
