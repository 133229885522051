@font-face {
  font-family: mainFont;
  src: url("https://www.activitycloud.io/font/Roboto-Regular.ttf");
}
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #f5f5f5;
  color: #242828;
  font-family: "Noto Sans", sans-serif;
  min-height: 100vh;
}

p {
  margin-bottom: 10px;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

.main__content {
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  padding: 20px;
}

input[type="email"],
input[type="password"],
input[type="text"],
input[type="url"],
input[type="date"],
input[type="number"],
input[type="tel"],
input[type="time"],
textarea {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 8px;
  width: 100%;
  font-size: min(16px, 1rem);
}

.activity__popup {
  display: grid;
  gap: 12px;
  padding: 20px;
}

button[type="submit"],
#sign-up__button,
.activity__button,
.cta__button {
  background-color: #242828;
  border: none;
  border-radius: 5px;
  color: #f5f5f5;
  cursor: pointer;
  font-size: min(16px, 1rem);
  padding: 10px;
  width: auto;
  transition: background-color 0.3s;
  &:has(i) {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 8px;
    align-items: center;
  }
  &:hover {
    background-color: #f44336;
  }
}

button[disabled] {
  background-color: #ccc !important;
  cursor: not-allowed;
}

.content__container {
  background: #fff;
  border: 1px solid #ccc;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.two__column__container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}

.custom-shape-divider-bottom-1712340739 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1712340739 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}

.custom-shape-divider-bottom-1712340739 .shape-fill {
  fill: #eef8fe;
}

ul,
ol {
  list-style-type: circle;
  list-style-position: inside;
  padding: 8px 0px;
  li {
    margin-bottom: 4px;
    padding-left: 16px;
  }
}
