div#toast__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
  background: rgba(0, 0, 0, 0.3);
}
aside#toast_message {
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: calc(50% - 175px);
  padding: 16px;
  padding-top: 50px;
  min-height: 150px;
  border-radius: 20px 20px 0px 0px;
  border: 1px solid #fff;
  border-bottom: none;
  width: 350px;
  color: #fff;
  animation: fade_up 0.7s forwards ease-in-out;
  z-index: 9999;
  box-shadow: 0px -4px 12px #4848481b;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    width: 100%;
    min-height: 200px;
    border-radius: 0px;
    left: 0;
    transform: translateX(0%);
  }
  .toast__close {
    position: absolute;
    padding: 0px;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: 1px solid transparent;
  }
  .toast__close i {
    color: #fff;
    font-weight: 300;
    font-size: 1rem;
    margin: 0px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: grid;
    place-content: center;
    &:hover {
      border: 1px solid #fff;
    }
  }

  &.success {
    background: #4e8d7c;
    .toast__icon {
      background: #045762;
    }
  }
  &.error {
    background: #db3056;
    .toast__icon {
      background: #851d41;
    }
  }
  &.warning {
    background: #ef8d32;
    .toast__icon {
      background: #c24914;
    }
  }

  .toast__icon {
    position: absolute;
    border: none;
    top: -30px;
    right: 50%;
    transform: translateX(50%);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid #fff;
    display: grid;
    place-content: center;
    i {
      color: #fff;
      font-size: 1.5rem;
    }
  }

  .toast__title {
    font-size: 1.3rem;
    margin-bottom: 8px;
    letter-spacing: 2px;
  }
  .toast__message {
    font-size: 1rem;
    margin-bottom: 8px;
  }
}

/* Animate a fade_up effect */
@keyframes fade_up {
  from {
    opacity: 0.5;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
