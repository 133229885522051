section.sign_up__container {
  // main container.
  position: relative;
  width: 100%;
  min-height: 83vh;
  padding: 24px;
  background: #725879;
  background-image: linear-gradient(
    to right top,
    #ffffff,
    #e7f1f7,
    #e7f1f7,
    #e7f1f7,
    #a0c6dd
  );
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 800px) {
    grid-template-columns: 1fr 2fr;
    padding: 50px;
  }

  .activity__hub__details {
    position: relative;
    padding: 12px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    place-content: center;
    img {
      width: 100%;
      max-width: 150px;
      height: auto;
      object-fit: cover;
      position: relative;
      margin: 20% auto;
      padding: 24px;
      @media screen and (min-width: 800px) {
        margin: 0 auto;
        max-width: 200px;
      }
    }
    h1 {
      text-align: center;
      font-size: 2rem;
    }
    p {
      max-width: 400px;
      margin: 0 auto;
      letter-spacing: 1px;
      line-height: 1.5;
      font-size: 0.9rem;
    }
  }
  .activity__hub__form__container {
    position: relative;
    display: grid;
    background: #e8e8e8;
    color: #444;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px #9d98ba4a;
    h1 {
      font-size: 2rem;
    }
    form {
      max-width: 500px;
      position: relative;
      place-self: center;
      display: flex;
      flex-direction: column;
      gap: 8px;
      label {
        display: grid;
        gap: 4px;
        span {
          font-size: 1rem;
        }
      }
      input {
        padding: 12px;
        border: 1px solid #ccc;
      }
      button[type="submit"] {
        text-transform: uppercase;
        margin-top: 12px;
        border-radius: 4px;
        transition: all 0.2s ease-in-out;
      }
      a,
      a:visited {
        color: #555;
        text-decoration: none;
        text-align: right;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .our__promise {
    position: absolute;
    bottom: 4px;
    right: 4px;
    color: #814978;
    font-size: 0.8rem;
    padding: 4px;
  }
}
